import "./polyfills";
import "./utils/checkBrowser";
/// SET ANONYMOUS ID | should be before store
import "./plugins/setAnonymousId";
import Vue from "vue";
import "@babel/polyfill";
// Components
import "./components";
// Plugins
import "./plugins";

// Application imports
import App from "./App";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import VueClipboard from "vue-clipboard2";
import Storage from "vue-ls";
import VueLodash from "vue-lodash";
import VueTidio from "vue-tidio";
import Vuetify from "vuetify/lib";
import VueCookie from "vue-cookie";
import "@mdi/font/css/materialdesignicons.css";
import SvgIcon from "vue-svgicon";
import WHITELABEL_QUERY from "@/graphql/queries/WhiteLabel.gql";

import { createProvider } from "./vue-apollo";
import { getFaviconUrl } from "@/utils/getLogo";
import { setVueGtm } from "@/plugins/vue-gtm";
import { handleThemes } from "@/utils/handleThemes";
// Vuetify
Vue.use(Vuetify);

// Lodash
Vue.use(VueLodash);

// Add Moment Library
const moment = require("moment");
const moment_timezone = require("moment-timezone");

Vue.use(require("vue-moment"), {
  moment,
});
Vue.prototype.$moment_timezone = moment_timezone;

Vue.config.productionTip = false;
Vue.use(VueCookie);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// LocalStorage plugin
Vue.use(Storage, {
  namespace: "wl_app_", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
});

Vue.use(SvgIcon, {
  tagName: "svgicon",
});

export const apolloProvider = createProvider();
let source = process.env.VUE_APP_DOMAIN
  ? process.env.VUE_APP_DOMAIN
  : window.location.hostname;

source = source.replace(".uant.space", "");
source = source.replace(".uant.dev", "");
source = source.replace(".consta.cc", "");
source = source.replace(".consta.app", "");
source = source.replace(".aumify.io", "");
source = source.replace(".spot-copy.com", "");

// Init WhiteLabel config
function formatWhiteLabel(wl) {
  if (wl.oauth2_public_setting) {
    wl.oauth2_public_setting = JSON.parse(wl.oauth2_public_setting);
  }

  if (wl.subscription_available) {
    wl.subscription_available = JSON.parse(wl.subscription_available);
  }
  if (wl.modules && wl.modules.length > 0) {
    wl.modules.map((item) => {
      if (item.public_config) {
        item.public_config = JSON.parse(item.public_config);
      }
    });
  }
  return wl;
}
const vuetify = new Vuetify({
  theme: {
    disable: false,
    dark: false,
  },
  icons: {
    iconfont: "mdi",
  },
});
function setWhiteLabel(wl, wlError = null) {
  Vue.ls.set("config", wl);
  store.state.app.config = wl;
  if (wlError) {
    store.state.app.wlError = wlError;
  }
}
function setupApp(wl) {
  if (wl.gtm_tag) {
    setVueGtm(wl.gtm_tag, router);
  }
  setTidio(wl.tidio_id);
  setFont(wl.font_family);
  setFavicon(getFaviconUrl(wl));
  setTitle(wl.name);
  setCssVars(wl);
}
let wl;
let wlError;
try {
  const { data } = await apolloProvider.defaultClient.query({
    query: WHITELABEL_QUERY,
    variables: {
      source: source,
    },
    fetchPolicy: "network",
  });
  wl = formatWhiteLabel(data.whiteLabel);
} catch (e) {
  // console.error(e);
  wl = Vue.ls.get("config") || {};
  wlError = e;
}

// set config to LS and vueX
setWhiteLabel(wl, wlError);
setupApp(wl);

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
if (wlError) {
  const path = window.location.pathname;
  if (path !== "/408") {
    sessionStorage.setItem("pathBeforeError", path);
  }
  await router.push("/408");
}
function setTidio(id) {
  // Bootstrap Tidio
  // Set in .env
  // https://github.com/nikolasp/vue-tidio
  if (id) {
    Vue.use(VueTidio, {
      appKey: id,
    });
  }
}

function setFont(font) {
  let query = "family=" + (font?.replace(" ", "+") || "Montserrat");
  var headID = document.getElementsByTagName("head")[0];
  var link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";

  headID.appendChild(link);
  link.href =
    "https://fonts.googleapis.com/css2?" +
    query +
    ":wght@400;500;600;700&display=swap";
}

function setTitle(title) {
  document.title = title || "Something went wrong";
}

function setFavicon(logo) {
  let headTitle = document.querySelector("head");

  let favIcons = [
    { rel: "apple-touch-icon" },
    { rel: "apple-touch-startup-image" },
    { rel: "shortcut icon" },
  ];

  favIcons.forEach(function (favIcon) {
    let setFavicon = document.createElement("link");
    setFavicon.setAttribute("rel", favIcon.rel);
    setFavicon.setAttribute("href", logo);
    headTitle.appendChild(setFavicon);
  });
}

function setColors(design, root) {
  root.setAttribute("data-theme", handleThemes(design));
  const COLOR_NAMES = [
    "light_theme_primary_color",
    "light_theme_secondary_color",
    "light_theme_primary_text_color",
    "light_theme_surface_color",
    "light_theme_surface_background",
    "light_theme_main_background",
    "light_theme_surface_color_70",
    "light_theme_surface_color_30",
    "light_theme_surface_color_10",
    "light_theme_surface_color_03",
    "light_theme_surface_background_03",
    "light_theme_surface_background_10",
    "dark_theme_primary_color",
    "dark_theme_secondary_color",
    "dark_theme_primary_text_color",
    "dark_theme_surface_color",
    "dark_theme_surface_background",
    "dark_theme_main_background",
    "dark_theme_surface_color_70",
    "dark_theme_surface_color_30",
    "dark_theme_surface_color_10",
    "dark_theme_surface_color_03",
    "dark_theme_surface_background_03",
    "dark_theme_surface_background_10",
  ];
  const getCssVarName = (queryName) => `--${queryName.split("_").join("-")}`;
  COLOR_NAMES.forEach((name) => {
    if (design?.[name]) {
      root.style.setProperty(getCssVarName(name), design[name]);
    }
  });
}

function setCssVars(wl) {
  const r = document.querySelector(":root");
  setColors(wl?.design, r);
  const fontFamily = wl?.font_family || "Montserrat";
  // Set Font
  r.style.setProperty(
    "--font-family",
    fontFamily + ", Roboto, Helvetica, Arial, sans-serif"
  );
}
